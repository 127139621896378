<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-snackbar color="green" top v-model="snackbarCopy" :timeout="timeout" content-class="text--center"
      style="text-align: center ;">
      URL copiada com sucesso!
    </v-snackbar>

    <div v-if="dialogControl">
      <div>
        <!-- MARKER DIALOG -->
        <router-view :isLoading="isPanelLoading" :dialog="dialogControl" :dialogContent="dialogContent"
          @somevent="clickOutside()" @copy="snackbarCopy = true"></router-view>
      </div>
    </div>

    <!-- LEGENDA DE FILTER MOBILE -->
    <div v-if="!isBig">
      <v-dialog v-model="dialogMobile">
        <v-card style="border-radius: 20px;padding:8px;background-color:rgba(255, 255, 255, 0.85)">
          <v-card-title style="padding: 6px !important">Filtros</v-card-title>
          <div class="card-content">

            <FilterButton class="filter-bts-mobile" name="comunidades" v-on:filter="handleFilter('pescadores')">
            </FilterButton>
            <FilterButton name="unidades de conservação" class="filter-bts-mobile" v-on:filter="handleFilter('unidades')">
            </FilterButton>
            <FilterButton class="filter-bts-mobile" name="estruturas" isEstrutura
              v-on:filter="handleFilter('estruturas')">
            </FilterButton>
            <div class="denuncia-wrapper-mobile">

              <FilterButton class="filter-bts-mobile" name="denúncias" v-model="denunciaFilter" isDenuncia
                v-on:filter="handleFilter('denuncias', $event)" :isEnabled="filterStates.denuncias"></FilterButton>

              <div class="legend-submenu-wrapper-mobile">
                <FilterButton class="filter-bts-mobile submenu-filter" name="áreas de fundeio" isDenuncia
                  v-on:filter="handleFilter('areas')" :isEnabled="filterStates.areas"></FilterButton>
                <FilterButton class="filter-bts-mobile submenu-filter" name="rotas de embarcações ou hidrovias" isDenuncia
                  v-on:filter="handleFilter('rotas')" :isEnabled="filterStates.rotas"></FilterButton>
                <FilterButton class="filter-bts-mobile submenu-filter" name="estruturas de apoio" isDenuncia
                  v-on:filter="handleFilter('estruturasApoio')" :isEnabled="filterStates.estruturasApoio"></FilterButton>
                <FilterButton class="filter-bts-mobile submenu-filter" name="mancha de óleo" isDenuncia
                  v-on:filter="handleFilter('mancha')" :isEnabled="filterStates.mancha"></FilterButton>
              </div>
            </div>
          </div>

        </v-card>
      </v-dialog>
    </div>

    <!-- LEGENDA DE FILTROS DESKTOP -->
    <div class="filter-container" v-if="isBig">
      <v-icon class="pl-4" medium>mdi-filter-multiple</v-icon>
      <FilterButton name="comunidades" v-on:filter="handleFilter('pescadores')"></FilterButton>
      <FilterButton name="estruturas" isEstrutura v-on:filter="handleFilter('estruturas')"></FilterButton>
      <v-menu open-on-hover bottom offset-y rounded="b-lg" :close-on-content-click="false" class="submenu-filter">
        <template v-slot:activator="{ on, attrs }">
          <div style="display: flex; align-items: center;" v-bind="attrs" v-on="on">
            <FilterButton name="denúncias" v-model="denunciaFilter" isDenuncia
              v-on:filter="handleFilter('denuncias', $event)" :isEnabled="filterStates.denuncias"></FilterButton>
          </div>
        </template>

        <div class="legend-submenu-wrapper">
          <FilterButton class="submenu-filter" name="áreas de fundeio" isDenuncia v-on:filter="handleFilter('areas')"
            :isEnabled="filterStates.areas"></FilterButton>
          <FilterButton class="submenu-filter" name="rotas de embarcações ou hidrovias" isDenuncia
            v-on:filter="handleFilter('rotas')" :isEnabled="filterStates.rotas"></FilterButton>
          <FilterButton class="submenu-filter" name="estruturas de apoio" isDenuncia
            v-on:filter="handleFilter('estruturasApoio')" :isEnabled="filterStates.estruturasApoio"></FilterButton>
          <FilterButton class="submenu-filter" name="mancha de óleo" isDenuncia v-on:filter="handleFilter('mancha')"
            :isEnabled="filterStates.mancha"></FilterButton>
        </div>
      </v-menu>

      <FilterButton name="unidades de conservação" v-on:filter="handleFilter('unidades')"></FilterButton>

    </div>

    <v-btn elevation="4" icon x-large class="style-change"
      @click="changeStyle"><v-icon>mdi-satellite-variant</v-icon></v-btn>
    <v-btn v-if="!isBig" elevation="4" icon x-large class="dialog-mobile"
      @click="dialogMobile = !dialogMobile"><v-icon>mdi-filter-multiple</v-icon></v-btn>


    <div id="map" class="mapa"></div>
  </v-container>
</template>
<script>
import mapboxgl from "mapbox-gl";
import GeoJSON from "geojson";
import polylabel from "polylabel";
import axios from 'axios'
import tabela from '@/mixin.js'
import FilterButton from "@/components/FilterButton.vue";

// GEOJSON-SYMBOL
import estaleiros from "../assets/estaleiros.geojson";
import pescadores from "../assets/pescadores.geojson";
import entidades from "../assets/entidades.geojson";
import refinarias from "../assets/refinarias.geojson";

// ICONS-SYMBOL
import estaleirosIcon from "../assets/icons/ico_estruturas.png";
import pescadoresIcon from "../assets/icons/ico_pesca_artesanal.png";
import entidadesIcon from "../assets/icons/ico_comunidades_pesqueiras.png";
import refinariasIcon from "../assets/icons/ico_estruturas.png";
import mapMarker from "../assets/icons/ico_denuncia.png";

// GEOJSON-FILL
import unidades from "../assets/unidades.geojson";
import fillFix from "../assets/fillfix.geojson";


export default {
  name: "Home",
  components: {
    FilterButton
  },
  mixins: [tabela],
  computed: {
    isBig() {
      return this.$vuetify.breakpoint.mdAndUp ? true : false
    }
  },
  data() {
    return {
      showDialog: false,
      snackbarCopy: false,
      timeout: 4000,
      copiedMessage: '',
      dialogMobile: false,
      denunciaFilter: '',
      filterStates: {
        denuncias: true,
        estruturasApoio: true,
        rotas: true,
        areas: true,
        mancha: true
      },
      isDataFetched: false,
      isMapLoaded: false,
      fHover: null,
      showModal: false,
      noImage: process.env.VUE_APP_NO_IMAGE_ROTATE_URL,
      layers: [
        {
          name: "Comunidades",
          value: "pescadores",
          visibility: true,
        },
        {
          name: "Entidades",
          value: "entidades",
          visibility: true,
        },
        {
          name: "Estaleiros",
          value: "estaleiros",
          visibility: true,
        },
        {
          name: "Refinarias",
          value: "refinarias",
          visibility: true,
        },
      ],
      rotasSelecionadas: [0, 1, 2],
      areasSelecionadas: [0, 1, 2, 3, 4,],
      estruturasSelecionadas: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      dialogContent: {},
      dialogControl: false,
      responseData: [],
      map: null,
      accessToken: process.env.VUE_APP_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAP_STYLE_URL,
      styleSatellite: "mapbox://styles/mapbox/satellite-streets-v11",
      centerDev: [-49.2658002, -25.4888851],
      center: [-43.12725, -22.81692],
      zoom: 10.6,
      unidades: unidades,
      fillFix: fillFix,
      assetsGeojson: {
        estaleiros: estaleiros,
        pescadores: pescadores,
        entidades: entidades,
        refinarias: refinarias
      },
      assetsIcon: {
        estaleirosIcon: estaleirosIcon,
        pescadoresIcon: pescadoresIcon,
        entidadesIcon: entidadesIcon,
        refinariasIcon: refinariasIcon
      },
      isPanelLoading: false
    }
  },
  async created() {
    await this.fetchData()
    if (this.$route.params.id) {
      this.isPanelLoading = true
      this.dialogControl = true
    }
    let indexDenuncia = null;
    this.responseData.some((item, index) => {
      if (item.id == this.$route.params.id && item.publicado) {
        indexDenuncia = index;
        return true;
      }
      return false;
    });

    let denunciaRota = this.responseData[indexDenuncia]

    if (!denunciaRota) { // Caso náo encontre denuncia com ID da URL, fica no endereço /mapa e fecha a dialog
      this.dialogControl = false
      if (this.$route.params.id) {
        this.$router.push('/mapa')
      }
      return
    }

    let photox = [];

    let featurePhotos = denunciaRota.photo;

    if (featurePhotos == "noImage") {
      photox[0] = this.noImage
    } else {
      photox = featurePhotos
    }

    this.dialogContent = {
      id: denunciaRota.id,
      photos: photox,
      createdAt: new Date(denunciaRota.createdAt).toLocaleDateString(
        "pt-BR",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      ),
      impacts: denunciaRota.impacts.split(";"),
      categorie: denunciaRota.categories,
      elements: denunciaRota.elements,
      relato: denunciaRota.relato,
      lat: denunciaRota.lat,
      lng: denunciaRota.lng
    };

    this.map.flyTo({
      center: [this.dialogContent.lng, this.dialogContent.lat],
      zoom: 12
    });
    this.isPanelLoading = false
  },
  mounted() {
    this.createMap()
  },
  methods: {
    createMap() {
      mapboxgl.accessToken = this.accessToken
      this.map = new mapboxgl.Map({
        container: 'map',
        style: this.mapStyle,
        center: this.center,
        zoom: this.zoom
      });

      this.map.on('load', () => {
        this.isMapLoaded = true;
        this.checkAndLoadData();
        this.loadLocalData()
        this.map.on('style.load', () => {
          this.loadLocalData();
        });


      })
    },
    checkAndLoadData() {
      if (this.isMapLoaded && this.isDataFetched) {
        this.loadResponseData()
      }
    },
    async changeStyle() {
      let styleID = 'satellite-streets-v12';
      let x = this.map.getStyle();
      let isBasic = x.name == 'Basic' ? true : false;
      const { data: newStyle } = await axios.get(
        `https://api.mapbox.com/styles/v1/${isBasic ? 'mapbox' : 'deolhonabaia'}/${isBasic ? styleID : 'ckz63btci000w14nn74e59vnz'}?access_token=${this.accessToken}`
      )
      await this.map.setStyle(newStyle);
    },
    handleFilter(filterValue, e) {
      this.filterStates[filterValue] = !this.filterStates[filterValue];
      if (filterValue == 'pescadores') {
        this.mapShow('pescadores')
        this.mapShow('entidades')
        return
      }
      if (filterValue == 'estruturas') {
        this.mapShow('estaleiros')
        this.mapShow('refinarias')
        return
      }
      if (filterValue == 'rotas') {
        this.mapShow(this.tabela.categorias[1]);
        return
      }
      if (filterValue == 'mancha') {
        this.mapShow(this.tabela.categorias[3]);
        return
      }
      if (filterValue == 'denuncias') {
        this.mapShow(this.tabela.categorias[0]);
        this.mapShow(this.tabela.categorias[1]);
        this.mapShow(this.tabela.categorias[2]);
        this.mapShow(this.tabela.categorias[3]);
        if (e) {
          this.filterStates['areas'] = true
          this.filterStates['rotas'] = true
          this.filterStates['estruturasApoio'] = true
          this.filterStates['mancha'] = true
        } else {
          this.filterStates['areas'] = false
          this.filterStates['rotas'] = false
          this.filterStates['estruturasApoio'] = false
          this.filterStates['mancha'] = false
        }



        return
      }
      if (filterValue == 'areas') {
        this.mapShow(this.tabela.categorias[0]);
        return
      }
      if (filterValue == 'estruturasApoio') {
        this.mapShow(this.tabela.categorias[2]);
        return
      }
      if (filterValue == 'unidades') {
        this.mapShow('unidades');
        return
      }

    },
    callback() {
      this.dialogControl = false;
    },
    async fetchData() {
      let respostaUnfiltered1 = await fetch(process.env.VUE_APP_BASE_URL + "denuncias?filters[publicado][$eq]=true&populate=*")
      let respostaUnfiltered2 = await respostaUnfiltered1.json()
      this.responseData = respostaUnfiltered2.data.map((item) => {
        if (item.photo) {
          if (item.photo.length > 1) {
            let photos = new Array();
            item.photo.forEach((element) => {
              photos.push(element);
            });
            item.photo = photos;
          }
        } else {
          item.photo = [];
          item.photo[0] = "noImage";
        }
        return item;
      });
      this.isDataFetched = true
      this.checkAndLoadData()
    },
    loadResponseData() {
      this.map.loadImage(mapMarker, (error, image) => {
        if (error) throw error;
        this.map.addImage("map-marker", image);



        let tabela = this.tabela.categorias;
        let count = 0;
        let geojson = {}
        this.tabela.categorias.forEach(categoria => {

          geojson = {
            type: "geojson",
            data: GeoJSON.parse(
              this.responseData.filter(
                (x) => x.categories == tabela[count]
              ),
              { Point: ["lat", "lng"] }
            ),
          }


          let countFeature = 0;

          geojson.data.features.forEach((feature) => {
            feature.id = count;
            feature.key = true;
            countFeature++;
          });

          this.map.addSource(tabela[count], geojson)

          this.map.addLayer({
            id: tabela[count],
            type: "symbol",
            source: tabela[count],
            paint: {
              "icon-opacity":
                [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  0.98,
                  0.90,
                ],
            },
            layout: {
              "icon-image": "map-marker",
              "icon-size": 0.10,
              "icon-allow-overlap": true,
              visibility: "visible",
            },
          });

          this.map.on("click", tabela[count], (e) => {
            this.openInfoBox(e.features[0]);
          });

          this.map.on("mousemove", tabela[count], (e) => {
            this.fHover = e.features[0];
            if (e.features.length > 0) {
              this.map.getCanvas().style.cursor = e.features.length ? "pointer" : "";
              this.map.setFeatureState({
                source: this.fHover.layer.source,
                id: this.fHover.id
              }, {
                hover: true
              });
            }
          });

          this.map.on("mouseleave", tabela[count], (e) => {
            this.map.getCanvas().style.cursor = "";
            this.map.setFeatureState({
              source: this.fHover.layer.source,
              id: this.fHover.id
            }, {
              hover: false
            });
            this.fHover = null;
          });

          count++
        })

        // Para a troca de estilos entre satélite e normal
        this.map.on("style.load", this.loadResponseData)

      });
    },
    openInfoBox(feature) {
      const featureResponse = this.responseData.filter((den) =>
        den.id == feature.properties.id
      )
      let lat = featureResponse[0].lat
      let lng = featureResponse[0].lng
      let photox = [];
      let featurePhotos = JSON.parse(feature.properties.photo)

      if (featurePhotos == "noImage") {
        photox[0] = this.noImage
      } else {
        photox = featurePhotos
      }
      const zoomAtual = this.map.getZoom();
      let zoom = 12;
      if (!this.isBig) {
        zoom = 15
      }
      if (zoomAtual > 12) {
        zoom = zoomAtual
      }
      this.map.flyTo({
        center: [featureResponse[0].lng, featureResponse[0].lat],
        zoom: zoom,
        duration: 1000

      })

      this.dialogContent = {
        id: feature.properties.id,
        photos: photox,
        createdAt: new Date(feature.properties.createdAt).toLocaleDateString(
          "pt-BR",
          { year: "numeric", month: "2-digit", day: "2-digit" }
        ),
        impacts: feature.properties.impacts.split(";"),
        categorie: feature.properties.categories,
        elements: feature.properties.elements,
        relato: feature.properties.relato,
        lat: lat,
        lng: lng
      };


      this.dialogControl = true;

      if (this.$route.params.id != feature.properties.id) {
        this.$router.push(`/mapa/${feature.properties.id}`)
      }

    },
    async loadLocalData() {

      this.addUnidade()
      Object.keys(this.assetsIcon).forEach(key => {
        this.map.loadImage(this.assetsIcon[key], (error, image) => {
          if (error) throw error;
          this.map.addImage(key, image)
        })
      })
      Object.keys(this.assetsGeojson).forEach(key => {
        if (this.map.getSource(key)) {
          this.map.removeLayer(key)
          this.map.removeSource(key)
        }

        this.map.addSource(key, { type: "geojson", data: this.assetsGeojson[key] });
        this.map.addLayer({
          id: key,
          type: "symbol",
          source: key,
          layout: {
            "icon-image": `${key}Icon`,
            "icon-size": 0.135,
            "icon-allow-overlap": true,
            visibility: "visible",
          },
        });

        this.map.on("click", key, (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.Name;

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          var htmlpopup = `<div style="font-size:1rem;font-family:Arial;padding:4px 6px">${description}</div>`;

          new mapboxgl.Popup({ className: "popup-class" })
            .setLngLat(coordinates)
            .setHTML(htmlpopup)
            .addTo(this.map);
        });

        this.map.on("mousemove", key, (e) => {
          if (e.features.length > 0) {
            this.map.getCanvas().style.cursor = e.features.length ? "pointer" : "";
          }
        });

        this.map.on("mouseleave", key, () => {
          this.map.getCanvas().style.cursor = "";
        });


      });

    },
    addUnidade() {

      let hoveredStateId = null;
      this.map.addSource("unidades", { type: "geojson", data: this.unidades });
      this.map.addLayer({
        id: "unidades",
        type: "fill",
        source: "unidades",
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": "rgb(60, 233, 36)",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.32,
            0.18,
          ],
        },
      });
      this.map.addLayer({
        id: "unidades-line",
        type: "line",
        source: "unidades",
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "rgb(0,0,0)",
          "line-width": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            1.5,
            1,
          ],
        },
      });


      this.map.on("mousemove", "unidades", (e) => {
        if (e.features.length > 0) {
          this.map.getCanvas().style.cursor = e.features.length ? "pointer" : "";
          if (hoveredStateId !== null) {
            this.map.setFeatureState(
              { source: "unidades", id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = e.features[0].id;
          this.map.setFeatureState(
            { source: "unidades", id: hoveredStateId },
            { hover: true }
          );
        }
      });
      this.map.on("mouseleave", "unidades", () => {
        this.map.getCanvas().style.cursor = "";
        if (hoveredStateId !== null) {
          this.map.setFeatureState(
            { source: "unidades", id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = null;
      });
      this.map.on("click", "unidades", (e) => {
        var p = polylabel(e.features[0].geometry.coordinates, 1.0);
        let coordinates;
        if (isNaN(p[0])) {
          coordinates = e.lngLat;
        } else {
          coordinates = p;
        }
        const nome = e.features[0].properties.nome;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        var htmlpopup = `
        <div class="font-weight-medium" style="font-size:14px;font-family:Ubuntu;padding:4px 6px">${nome}</div>
        `;
        new mapboxgl.Popup({ className: "popup-class" })
          .setLngLat(coordinates)
          .setHTML(htmlpopup)
          .addTo(this.map);
      });

      this.map.addSource("fillfix", { type: "geojson", data: this.fillFix });

      this.map.addLayer({
        id: "fillfixlayer",
        type: "fill",
        source: "fillfix",
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": "rgb(255, 255, 255)",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0,
            0,
          ],
        },
      });

      this.map.on("click", "fillfixlayer", (e) => {
        var htmlfix = '<div> </div>'
        new mapboxgl.Popup({ className: "popup-class" })
          .setLngLat([62.2949, 89.7879])
          .setHTML(htmlfix)
          .addTo(this.map);
      });

    },
    mapShow(layerId) {
      const visibility = this.map.getLayoutProperty(layerId, "visibility");
      const isFill = this.map.getLayer(layerId).type;
      let state = null;
      state = visibility == "visible" ? "none" : "visible";
      this.map.setLayoutProperty(layerId, "visibility", state);
      if (isFill == "fill") {
        this.map.setLayoutProperty(`${layerId}-line`, "visibility", state);
      }
    },
    getUniqueFeatures(features, comparatorProperty) {
      const uniqueIds = new Set();
      const uniqueFeatures = [];
      for (const feature of features) {
        const id = feature.properties[comparatorProperty];
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
          uniqueFeatures.push(feature);
        }
      }
      return uniqueFeatures;
    },
    check: function (e) {
      e.cancelBubble = true;
    },
    changeVisibilitySebastiao(sourceId, element) {
      // this.checks[element] = !this.checks[element];
      const features = this.map.querySourceFeatures(sourceId);
      const filteredFeatures = features.filter(
        (f) => f.properties.elements == element
      );
      const uniqueFeatures = this.getUniqueFeatures(filteredFeatures, "id");
      uniqueFeatures.forEach((feat) => {
        const state = this.map.getFeatureState({ source: sourceId, id: feat.id });
        if (!state.visible) {
          this.map.setFeatureState(
            { source: sourceId, id: feat.id },
            { visible: true }
          );
          return;
        }

        if (state.visible == true) {
          this.map.setFeatureState(
            { source: sourceId, id: feat.id },
            { visible: false }
          );
          return;
        }
      });

    },
    clickOutside() {
      this.$router.push('/mapa')
      this.dialogControl = false
    }
  }
}
</script>
<style scoped>
.submenu-filter {
  display: relative;
  left: 20px;
}

.submenu-filter {
  padding: 5px;
}



.filter-container {
  display: flex;
  padding-right: 8px;
  position: absolute;
  justify-content: space-between;
  left: 25%;
  right: 25%;
  border-radius: 10px;
  top: 8px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 200;
  height: 45px;
}


.style-change {
  position: absolute;
  z-index: 5;
  top: 8px;
  right: 10px;
  width: 70px;
  height: 70px;
  background: #e7e3e3;
  border-radius: 50%;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1),
    -6px -6px 10px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.denuncia-wrapper-mobile {
  background-color: #ee74174f;
  border-radius: 20px;
  padding: 4px 0px 0px 4px;
}

.legend-submenu-wrapper-mobile {
  background-color: #ee74172b;
  border-radius: 15px;
  margin-left: 26px;
  padding-left: 6px;
}

.legend-submenu-wrapper {
  background-color: #ffffffd9;
  padding: 5px;
}


.dialog-mobile {
  position: absolute;
  z-index: 5;
  top: 8px;
  left: 10px;
  width: 70px;
  height: 70px;
  background: #e7e3e3;
  border-radius: 50%;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1),
    -6px -6px 10px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.style-change:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4),
    -4px -4px 8px rgba(255, 255, 255, 0.7);
}

.style-change .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #888888;
}

.style-change .icon:hover {
  color: #333333;
}

.filter-bts-mobile {
  padding: 8px 4px;
}

.popup-class {
  background-color: blue !important;
  font-family:  'Arial' !important;  
  width: 200px;

  font-size: 28px;
}

.mapa {
  width: 100vw;
  height: calc(100vh - 64px);
}

@media (min-width:1380px) {
  .filter-container {
    left: 30%;
    right: 30%;
  }
}

</style>