<template>
  <div @click="toggleCheckbox" class="custom-checkbox-wrapper">
    <div :class="{
      'custom-checkbox-checked': isChecked && !isEstrutura && !isDenuncia,
      'custom-checkbox': !isEstrutura && !isDenuncia,
      'cor-estrutura': isEstrutura && isChecked,
      'custom-checkbox-estrutura': isEstrutura,
      'custom-checkbox-denuncia': isDenuncia,
      'cor-denuncia': isDenuncia && isEnabled
    }">
      <div class="circle"></div>
    </div>
    <div class="custom-checkbox-label">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    isEnabled: {
      type: Boolean,
      default: true
    },
    color: String,
    isEstrutura: Boolean,
    isDenuncia: Boolean,
    control: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isChecked: this.isEnabled,
    };
  },
  watch: {
    'filterStates.denuncias'(newVal) {
      if (!newVal) {
        this.filterStates.areas = false;
        this.filterStates.rotas = false;
        this.filterStates.estruturasApoio = false;
      }
    },
  },
  methods: {
    toggleCheckbox() {
      this.isChecked = !this.isChecked;
      this.$emit('filter', this.isChecked);
    },
  },
};
</script>

<style>
.custom-checkbox {
  width: 25px;
  height: 25px;
  border: 2px solid #31a719;
  /* Border for the circle */
  border-radius: 50%;
  /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-checkbox-estrutura {
  width: 25px;
  height: 25px;
  border: 2px solid #091b27;
  /* Border for the circle */
  border-radius: 50%;
  /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-checkbox-denuncia {
  width: 25px;
  height: 25px;
  border: 2px solid #ff5f0f;
  /* Border for the circle */
  border-radius: 50%;
  /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-checkbox-checked {
  background-color: #31a719;
  /* Fill the circle when checked */
}

.cor-estrutura {
  background-color: #091b27;
  /* Fill the circle when checked */
}

.cor-denuncia {
  background-color: #ff5f0f;
  /* Fill the circle when checked */
}

.circle {
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  width: 25px;
}

.circle:hover {
  cursor: pointer;
}

.custom-checkbox-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox-label {
  font-size: 14px;
  padding-left: 6px;
}
</style>