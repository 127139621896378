export default {
  data() {
    return {
      tabela: {
        categorias: [
          "Áreas de fundeio",
          "Rotas de embarcações ou hidrovias",
          "Estruturas de apoio",
          "Mancha de Óleo",
        ],
        elementosMancha: [
          "Área de influência direta",
          "Área de influência indireta",
          "Estrutura / embarcações offshore",
          "Mancha órfã",
        ],
        elementosAreas: [
          "Embarcação de grande porte diversa",
          "Navio de petróleo ou gás",
          "Embarcações de apoio offshore",
          "Rebocadores",
          "Embarcações e carcaças abandonadas ou naufragadas",
        ],
        elementosRotas: [
          "Boias de sinalização e demarcação de rotas",
          "Placas e postes de sinalização e informativos",
          "Atividade de dragagem",
        ],
        elementosEstruturas: [
          "Estaleiro",
          "Dutos de oleodutos ou gasodutos marítimos (offshore)",
          "Dutos de oleodutos ou gasodutos terrestres (onshore)",
          "Terminal aquaviário de gás e petróleo",
          "Plataforma ou píer",
          "Refinarias",
          "Instalação portuária",
          "Empresas de derivados do petróleo",
          "Frente de obras/manutenção",
        ],
        impactos: [
          "Alteração da dinâmica dos ecossistemas terrestres",
          "Alteração das características das comunidades tradicionais",
          "Alteração na qualidade da água por poluentes químicos",
          "Alteração na qualidade do sedimento por poluentes químicos",
          "Aquecimento da água",
          "Assoreamento, erosão e desassoreamento",
          "Aumento na turbidez na massa d’água ocasionado por dragagens",
          "Apropriação e ocupação do espaço marítimo",
          "Desapropriações e realocação das populações",
          "Despejo de efluentes químicos",
          "Emissão sonora sobre o meio biótico",
          "Mortandade de mangues / vegetação aquática",
          "Mortandades de peixes / crustáceos / aves ou outros animais silvestres",
          "Mudança na hidrodinâmica do estuário",
          "Perda da biodiversidade",
          "Poluição luminosa ocasionada por refinaria, terminal, plataforma, navio, rebocadores, píer ou base de apoio de petróleo e gás",
          "Poluição do ar",
          "Proibição ou restrição de acesso aos pesqueiros tradicionais",
          "Resfriamento da água",
          "Riscos à saúde das populações e dos animais",
          "Supressão vegetal, corte e desmatamento",
        ],
      },
    };
  },
};
