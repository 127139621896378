var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-checkbox-wrapper",on:{"click":_vm.toggleCheckbox}},[_c('div',{class:{
    'custom-checkbox-checked': _vm.isChecked && !_vm.isEstrutura && !_vm.isDenuncia,
    'custom-checkbox': !_vm.isEstrutura && !_vm.isDenuncia,
    'cor-estrutura': _vm.isEstrutura && _vm.isChecked,
    'custom-checkbox-estrutura': _vm.isEstrutura,
    'custom-checkbox-denuncia': _vm.isDenuncia,
    'cor-denuncia': _vm.isDenuncia && _vm.isEnabled
  }},[_c('div',{staticClass:"circle"})]),_c('div',{staticClass:"custom-checkbox-label"},[_vm._v(" "+_vm._s(_vm.name)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }